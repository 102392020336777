.responsibility {
  font-family: Inter, Georgia, sans-serif;
}
.responsibility__link {
  color: black;
  text-decoration: none;
  /* pointer-events: none; */
}
.responsibility__link:hover {
  cursor: pointer;
  opacity: 0.6;
}
