/* .usefulInformation__heading {
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
} */
.usefulInformation__list {
  list-style-type: none;
  padding: 0;
  padding-left: 20px;
}
@media screen and (max-width: 600px) {
  .usefulInformation__list {
    padding-left: 0;
  }
}
