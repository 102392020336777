.contacts {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
}
.contacts__map {
  width: 48%;
}
.contacts__mapImg {
  width: 100%;
  object-fit: contain;
}
.contacts__list {
  width: 48%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.contacts__point {
  margin: 0;
}
.contacts__pointIcon {
  width: 25px;
  margin-right: 5px;
  /* height: 20px; */
}
.contacts__pointHeading {
  display: flex;
  align-items: center;
  margin-top: 0;
}
.contacts__pointText {
  margin-left: 30px;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  /* font-weight: 400; */
  font-size: 15px;
  line-height: 18px;
}
@media screen and (max-width: 600px) {
  .contacts {
    flex-direction: column;
    align-items: center;
  }
  .contacts__map {
    width: 100%;
  }
  .contacts__list {
    width: 100%;
  }
  .contacts__pointHeading {
    margin: 18px 0 10px;
  }
  .contacts__pointText {
    margin: 0;
    margin-left: 30px;
  }
}
