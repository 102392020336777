.scholarships__text {
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.scholarships__textUnderline {
  text-decoration: underline;
}
