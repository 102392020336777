.noPage {
  display: flex;
  justify-content: center;
  margin: 50px auto;
}
.noPage__text {
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
