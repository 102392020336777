.vacancy {
  margin: 20px auto;
}
.vacancy__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.vacancy__point {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ebebeb;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  opacity: 0.8;
}
.vacancy__pointHeader {
  width: 45%;
  padding: 1%;
  margin: 0;
  margin-right: 1%;
  overflow: hidden;
}
.vacancy__pointText {
  width: 25%;
  padding: 1%;
  margin: 0;
  overflow: hidden;
}
.vacancy__point:first-child {
  border-top: none;
}
.vacancy__none {
  text-align: center;
}
@media screen and (max-width: 600px) {
  .vacancy__point {
    font-size: 12px;
    line-height: 15px;
  }
}
