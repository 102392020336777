.managers__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.manager {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 28vw;
  margin: 20px 1vw;
  border-radius: 10px;
}
.manager__contentPhoto {

  display: grid;
  width: 25vw;
  height: 25vw;
}
/* .manager__photo {
  width: 100%;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  opacity: 1;
} */
.manager__photo {
  width: 100%;
  position: relative;
  z-index: 1;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.5s linear;
  /* transition: opacity 0.5s linear, background-color 0.5s linear; */
}
.manager__contentPhoto:hover .manager__photo {
  opacity: 0.5;
}
.manager__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 20px 10px;
  z-index: 2;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  color: #ffffff;
  opacity: 0;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: opacity 0.5s linear, color 0.5s linear;
}
.manager__description:hover {
  opacity: 1;
  color: #000000;
}
.manager__description h4 {
  margin: 0 0 25px;
}
.manager__descriptionText {
  /* height: 100%; */
  margin: 0;
  overflow: hidden;
  /* display: inline;
  vertical-align: bottom; */
}
.manager__name {
  text-align: center;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.manager__post {
  margin: 0;
  text-align: center;
  color: #024896;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

@media screen and (max-width: 1000px) {
  .manager {
    width: 27vw;
  }
}

@media screen and (max-width: 600px) {
  .managers__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: nowrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .manager {
    width: 70vw;
    margin: 10px 5px;
  }
  .manager__contentPhoto {
    width: 100%;
    height: 70vw;
    object-fit: contain;
  }
  .manager__name {
    font-size: 22px;
    line-height: 26px;
  }
  .manager__post {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (min-width: 1660px) {
  .manager {
    width: 465px;
    margin: 20px 17px;
  }
  .manager__contentPhoto {
    width: 415px;
  }
}
