.noRoute {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
}
.noRoute__heading {
  margin: 0;
  margin-top: 246px;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
  color: #000000;
}
.noRoute__text {
  margin: 0;
  margin-top: 5px;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.noRoute__link {
  margin-top: 208px;
  font-family: Inter, Georgia, sans-serif;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2be080;
  background-color: #ffffff;
  border: none;
  list-style-type: none;
}
.noRoute__link:hover {
  cursor: pointer;
  opacity: 0.8;
}
@media screen and (max-width: 800px) {
  .noRoute__heading {
    margin-top: 408px;
  }
  .noRoute__link {
    margin-top: 184px;
  }
}
@media screen and (max-width: 400px) {
  .noRoute__heading {
    margin-top: 329px;
    font-size: 80px;
    line-height: 97px;
  }
  .noRoute__text {
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px;
  }
  .noRoute__link {
    margin-top: 284px;
    font-size: 12px;
    line-height: 15px;
  }
}
