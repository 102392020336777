.popup {
  transition: visibility 1s, opacity 1s linear;
}
.popupImg {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
}
.popupImg__cell {
  align-self: center;
  position: absolute;
  top: 90px;
}
.popupImg__img {
  max-width: 75vw;
  max-height: 75vh;
  object-fit: contain;
}
.popupImg__text {
  margin: 0;
  margin-top: 10px;
  color: #000000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.popup_opened {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 1s linear;
  z-index: 10;
}
.popup__close {
  position: absolute;
  top: -40px;
  right: -40px;
  width: 32px;
  height: 32px;
  padding: 0;
  /* background-image: url('../../../images/popup-close-icon.svg'); */
  background-color: rgba(0, 0, 0, 0.1);
  /* background-repeat: no-repeat;
  background-position: center; */
  background-size: cover;
  border: none;
}
.popup__close:hover {
  cursor: pointer;
  opacity: 0.6;
}

@media screen and (max-width: 602px) {
  .popupImg__cell {
    top: 136px;
  }
  .popup__close {
    top: -35px;
    right: 0;
    width: 20px;
    height: 20px;
    /*  margin-bottom: 16px; */
  }
}

@media screen and (min-width: 1500px) {
  .popupImg__cell {
    top: 250px;
  }
}
