.gallerySwiper {
  height: calc(55vw - 40px);
  object-fit: contain;
}
.gallerySwiper__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mySwiper2 {
  width: 100%;
  height: calc(90% - 7px);
}
.swiper-watch-progress {
  margin-top: 7px;
  height: 10%;
}
@media screen and (min-width: 1600px) {
  .gallerySwiper {
    height: 760px;
  }
}
@media screen and (max-width: 760px) {
  .gallerySwiper {
    width: 90%;
    margin: 0 auto;
    height: calc(65vw - 40px);
    object-fit: contain;
  }
  .mySwiper2 {
    width: 100%;
    height: calc(80% - 7px);
  }
  .swiper-watch-progress {
    margin-top: 7px;
    height: 20%;
  }
}