.worksAndServices {
  font-family: Inter, Georgia, sans-serif;
}
.worksAndServices__list {
  font-weight: bold;
}
.worksAndServices__point {
  padding-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .worksAndServices__list {
    padding: 0;
    padding-left: 20px;
  }
}
