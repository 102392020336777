.attention__link {
  width: max-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0 32px 100px;
  text-decoration: none;
  font-family: Tahoma;
  font-size: 16px;
  /* font-weight: 400; */
  line-height: 24px;
  color: #C72424;
}
.attention__linkContent {
  display: flex;
}
.attention__linkContentButton {
  font-size: 20px;
  /* font-weight: 700; */
  line-height: 24.2px;
  color: #F5F5F5;
  background-color: #C72424;
  border-radius: 10px;
  padding: 12px 14px;
}
.attention__linkContentText {
  text-align: center;
  text-decoration: underline;
}

@media screen and (max-width: 1110px) {
  .attention__link {
    font-size: 12px;
  }
  .attention__linkContentButton {
    font-size: 16px;
    padding: 8px 12px;
  }
}

@media screen and (max-width: 800px) {
  .attention__link {
    margin: 20px auto 32px;
  }
  .attention__linkContentButton {
    font-size: 14px;
    padding: 4px 10px;
  }
}

@media screen and (max-width: 650px) {
  .attention__link {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
  }
  .attention__linkContentSpecText {
    display: none;
  }
}