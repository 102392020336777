.education__list {
  list-style-type: none;
  padding: 0;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  /* font-weight: 400; */
  font-size: 16px;
  line-height: 18px;
}
.documents__pointList {
  list-style-type: none;
  padding: 0;
  padding-left: 20px;
}
.education__nestedList {
  padding: 0;
  padding-left: 20px;
}
.education__pointText {
  padding: 0;
  padding-left: 20px;
}
.education__pointTextLink {
  text-decoration: none;
  color: #024896;
}
