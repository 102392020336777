.documents__list {
  list-style-type: none;
  padding-left: 20px;
}
.documents__point {
  /* display: flex;
  align-items: center;
  padding: 10px; */
}
.documents__link {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #000000;
}
.documents__icon {
  width: 30px;
  height: 30px;
}
.documents__text {
  margin: 0;
  margin-left: 20px;
  font-family: Inter, Georgia, sans-serif;
  font-style: normal;
  /* font-weight: 400; */
  font-size: 16px;
  line-height: 18px;
  color: #024896;
}
@media screen and (max-width: 600px) {
  .documents__list {
    padding-left: 0;
  }
  .documents__link {
    padding: 10px 0;
  }
}
